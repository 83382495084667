import { forwardRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import './enterKpi.scss'
export default function EnterkpiComponent({
  inputHandler,
  onFormSubmit,
  startDate,
  kpiMonthShow,
  kpiYearShow,
  onMonthChange,
  setOpenKpiSlider,
  formEdited,
  showToast,
  setShowToast,
  onKpiSetSubmit,
  kpiScreen,
  setKpiScreen,
  kpiListing,
  kpiSelectedUnits,
  customKpiInputHandler,
  customApiDetails,
  onUnitClick,
  onUnselectClick,
  setShowAddCustomKpi,
  showAddCustomKpi,
  customKpiFormEdited,
  enterKpiListShow,
  setEditKpiShow,
  modalShow,
  setModalShow,
  editSetKpiSubmit,
  plant_code,
  setPlantCode,
  flexibleEquipmentEnabled,
  processList,
}) {
  const ExampleCustomInput = forwardRef(({ onClick }, ref) => (
    <i onClick={onClick} ref={ref} className=" material-icons notranslate graphoptionicons">
      arrow_drop_down
    </i>
  ));
  ExampleCustomInput.displayName = 'ExampleCustomInput';
  const renderTooltip = (props) => {
    return <Tooltip {...props}>Set KPI</Tooltip>;
  };

  const handleClose = () => setModalShow(false);
  // const handleShow = () => setModalShow(true);
console.log('plant_code',plant_code)
  return (
    <div
      className={
        formEdited ? 'sidebar-slider enterKpiSlider formEdited' : 'sidebar-slider enterKpiSlider'
      }>
      <Alert
        className="sidebar-slider_alert"
        show={showToast}
        variant="success"
        onClose={() => setShowToast(false)}>
        <p className="text-center">KPI values updated successfully</p>
      </Alert>

      <Modal show={modalShow} onHide={handleClose} centered>
        <div className="modal-wrap">
          <Modal.Header closeButton>
            <h5>Warning!</h5>
          </Modal.Header>
          <Modal.Body>
            Please note that changing or removing a KPI setting also will{' '}
            <strong> remove all previous KPI entries.</strong> <br /> Energy data is still intact
            but you will have to re-enter the KPI values for the previous months.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="btn-grad"
              onClick={() => {
                editSetKpiSubmit();
                handleClose();
              }}>
              Continue
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <i
        className="sidebar-slider_close material-icons notranslate graphoptionicons"
        onClick={() => {
          setOpenKpiSlider(false);
        }}>
        cancel
      </i>
      {
              flexibleEquipmentEnabled &&  <div className="process-nav-dropdown mb-4">
              <Dropdown className="bt-dropdown-nav plant-dropdown ">
                <Dropdown.Toggle size="sm" variant="secondary">
                  Plants
                </Dropdown.Toggle>
  
                <Dropdown.Menu>
                  {processList?.map((process, i) => {
                    return (
                      <Dropdown.Item key={`processdropdown${i}`} onClick={()=>{
                        setPlantCode(process?.plant_code)
                      }
                      }>
                        
                        <div style={{marginLeft:'8px'}} className={process?.plant_code === plant_code ? 'fw-bold' : ''}>
                        {process.name}
                        </div>
                      
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            }
      {kpiScreen ? (
        <div className="enterKpiSlider_enter">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}>
            <span
              onClick={() => {
                setKpiScreen(false);
                setEditKpiShow(true);
              }}
              className="top-left-btn">
              {' '}
              <i className=" material-icons notranslate graphoptionicons ">settings</i>
            </span>
          </OverlayTrigger>

          <h3>Enter KPI</h3>
          <p className="mt-2 mb-2 txt-15">for</p>
          <div className="flex-c-m position-relative mb-3">
            <div className="kpiDatepicker_monthyear">
              <h3>{kpiMonthShow}</h3>
              <p>{kpiYearShow}</p>
            </div>
            {/* <label   className="kpiDatepicker_label"> */}

            <DatePicker
              selected={startDate}
              onChange={onMonthChange}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
              calendarClassName="kpiDatepickerCalendar"
              className="kpiDatepicker"
              customInput={<ExampleCustomInput />}
              openToDate={startDate}
            />

            {/* <i className=" material-icons notranslate graphoptionicons">arrow_drop_down</i>    
                            </label> */}
          </div>

          <form onSubmit={onFormSubmit} className="kpiEnterForm">
            {/* { 
                            Object.keys(formData).map((item, i) => {
                            return <div key={i} className="form-group">
                                    <label className="mb-1">{formData[item].label} </label>
                                    <input
                                        className ="form-control shadow border-0 py-4"
                                        type="number"
                                        min="0"
                                        step="any"
                                        name={formData[item].name}
                                        value={formData[item].value}
                                        onChange={inputHandler}
                                    ></input> 
                                </div>
                                
                            })
                            } */}

            {enterKpiListShow &&
              enterKpiListShow.map((eachKpi, i) => {
                return (
                  <div key={i} className="form-group" id={eachKpi.id}>
                    <label className="mb-1">
                      {eachKpi.kpi.name} <span className="unitname">({eachKpi.kpi_unit.name})</span>{' '}
                    </label>
                    <input
                      className="form-control shadow border-0 py-4"
                      type="number"
                      min="0"
                      step="any"
                      id={eachKpi.id}
                      name={eachKpi.kpi.name}
                      defaultValue={eachKpi.value}
                      value={eachKpi.value}
                      onChange={(e) => inputHandler(e, eachKpi.id)}></input>
                  </div>
                );
              })}

            <button
              className="btn mt-4 btn-grad pr-5 pl-5 mx-auto"
              type="submit"
              disabled={!formEdited}>
              Update
            </button>
          </form>
        </div>
      ) : (
        <div
          className={customKpiFormEdited ? 'enterKpiSlider_set formEdited' : 'enterKpiSlider_set'}>
          <h3>Set KPIs</h3>
          <p className="mt-2 mb-2 txt-15">
            Set how you track your KPIs. The values you enter are divided by monthly kWh and can be
            displayed in the KPI menu.
          </p>
          <div className="mt-4 text-left">
            <form onSubmit={onKpiSetSubmit} className="text-start">
              {kpiListing &&
                kpiListing.map((eachKpi, i) => {
                  return (
                    <>
                      <div key={i} className="flex-c">
                        <input
                          className="mr-1  daz-checkbox left"
                          id={`kpi_${eachKpi.id}`}
                          name={`kpi_${eachKpi.id}`}
                          type="checkbox"
                          checked={kpiSelectedUnits.some((o) => o.kpi == eachKpi.id)}
                          disabled={!kpiSelectedUnits.some((o) => o.kpi == eachKpi.id)}
                          // inline
                          onChange={(e) => onUnselectClick(e, eachKpi.id)}
                        />
                        <label htmlFor={`kpi_${eachKpi.id}`}></label>

                        <p key={i}>
                          {eachKpi.name}
                          {/* <a href="javascript:void(0)" onClick={e => onUnselectClick(e,eachKpi.id)} className="text-danger font-sm">Unselect</a> */}
                        </p>
                      </div>
                      <div className="flex-c flex-wrap mt-1 mb-3">
                        {eachKpi.kpiunits.map((eachKpiUnit, i) => {
                          // var arrayIndex = kpiSelectedUnits.findIndex(it => Object.keys(it)[0] == eachKpi.id);
                          if (
                            kpiSelectedUnits.some(
                              (o) => o.kpi == eachKpi.id && o.kpi_unit == eachKpiUnit.id
                            )
                          ) {
                            return (
                              <button
                                key={i}
                                onClick={(e) => onUnitClick(e, eachKpi.id, eachKpiUnit.id)}
                                id={eachKpiUnit.id}
                                data-kpiid={eachKpi.id}
                                className="graph-filter-btn  mb-2 graph-filter-active">
                                {eachKpiUnit.name}
                              </button>
                            );
                          } else {
                            return (
                              <button
                                key={i}
                                onClick={(e) => onUnitClick(e, eachKpi.id, eachKpiUnit.id)}
                                id={eachKpiUnit.id}
                                data-kpiid={eachKpi.id}
                                className="graph-filter-btn  mb-2 ">
                                {eachKpiUnit.name}
                              </button>
                            );
                          }
                        })}
                      </div>
                    </>
                  );
                })}

              <p className="text-start font-bold">Custom KPI</p>
              {showAddCustomKpi ? (
                <div className="mt-1">
                  <div className="form-group">
                    <label className="mb-1"> KPI name (chart label)</label>
                    <input
                      className="form-control shadow border-0 py-4"
                      name="custom_kpi_name"
                      type="text"
                      value={customApiDetails.custom_kpi_name.value}
                      onChange={customKpiInputHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1"> KPI unit</label>
                    <input
                      className="form-control shadow border-0 py-4"
                      type="text"
                      name="custom_kpi_unit"
                      value={customApiDetails.custom_kpi_unit.value}
                      onChange={customKpiInputHandler}
                    />
                  </div>
                </div>
              ) : (
                <button
                  className="graph-filter-btn mt-2"
                  onClick={() => {
                    setShowAddCustomKpi(true);
                  }}>
                  Add +{' '}
                </button>
              )}
              <button className="btn mt-4 btn-grad pr-5 pl-5 mx-auto" type="submit">
                Confirm
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
