import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ViewKpiMachineContainer from '../../../modules/machinekpi/containers/view.kpi.machine.container';
import useHttpApiCall from '../../../services/useHttpApiCall';

function KpiMachineLayoutComponent(props) {
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const [kpiMachineStatus, setKpiMachineStatus] = useState(null);
  const [idParams, setIdParams] = useState(null);
  const [currentKpiId, setCurrentKpiId] = useState(null);
  const [viewKpiList, setViewKpiList] = useState([]);
  const [isAllKpiSet, setIsAllKpiSet] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [currentKpiStatus, setCurrentKpiStatus] = useState(null);
  const [showKpiBox, setShowKpiBox] = useState(true);

  const location = useLocation();
  useEffect(() => {
    let apiParams;
    if (props.processDetails.drilldownlevel == 'Process') {
      apiParams = {
        process_id: props.processDetails.process_id,
        sub_process_id: props.processDetails.sub_process_id
      };
    }
    else if (props.processDetails.drilldownlevel=='ProcessLayer') {
      apiParams = {
        layer_id: props.processDetails.process_id,
        plant_code:props.processDetails.plant_code,
        // sub_process_id: props.processDetails.sub_process_id,
        layer_type:'process'
      };
    }
    else if (props.processDetails.drilldownlevel=='EquipmentLayer') {
      apiParams = {
        layer_id: props.processDetails.process_id,
        plant_code:props.processDetails.plant_code,
        // sub_process_id: props.processDetails.sub_process_id,
        layer_type:'equipment'
      };
    } 
    else if (props.processDetails.drilldownlevel=='ComponentLayer') {
      apiParams = {
        layer_id: props.processDetails.process_id,
        plant_code:props.processDetails.plant_code,
        // sub_process_id: props.processDetails.sub_process_id,
        layer_type:'component'
      };
    }
    else {
      apiParams = {
        process_id: props.processDetails.process_id,
        sub_process_id: props.processDetails.sub_process_id,
        equipment_id: props.processDetails.equipment_id
      };
    }
    setIdParams(apiParams);
    setPayload({
      auth: true,
      url: 'v0/equipments/kpis/enabled-status/',
      params: apiParams
    });
  }, [location.pathname, updateState]);

  useEffect(() => {
    if (data && data.data) {
      setKpiMachineStatus(data.data.data.is_kpi_enabled);
      setIsAllKpiSet(data.data.data.is_all_kpi_set);
      setCurrentKpiStatus(data.data.data);
      setShowKpiBox(!data.data.data.is_kpi_eye_hidden);
    }
  }, [data]);

  return (
    <div>
      {
        <ViewKpiMachineContainer
          showKpiBox={showKpiBox}
          setShowKpiBox={setShowKpiBox}
          setUpdateState={setUpdateState}
          updateState={updateState}
          setViewKpiList={setViewKpiList}
          viewKpiList={viewKpiList}
          kpiMachineStatus={kpiMachineStatus}
          idParams={idParams}
          setKpiMachineStatus={setKpiMachineStatus}
          currentKpiId={currentKpiId}
          setCurrentKpiId={setCurrentKpiId}
          isAllKpiSet={isAllKpiSet}
          currentKpiStatus={currentKpiStatus}
          setCurrentKpiStatus={setCurrentKpiStatus}
        />
      }
    </div>
  );
}

export default KpiMachineLayoutComponent;
