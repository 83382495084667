import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { chartCommonConfig, chartTitleConfig, chartYaxisConfig } from '../../../config/chartCommon';
import useHttpApiCall from '../../../services/useHttpApiCall';
import eventFire from '../../../utilities/eventFire-inside-highcharts';
import MachineKpiComparisonGraphComponent from '../components/machine.kpi.comparison.graph.component';
import MachineKpiEnterContainer from './machine.kpi.enter.container';
import MachineKpiEquipmentFilterContainer from './machine.kpi.equipment.filter.container';
import { useSearchParams } from 'react-router-dom';

function MachineKpiComparisonGraphContainer(props) {
  //const INITIALFILTER = "1week";
  const charRef = useRef(undefined);
  const [chartApiData, setChartApiData] = useState([]);
  const [payload, setPayload] = useState(undefined);
  const [data] = useHttpApiCall(payload);
  const userAccessibility = useSelector((state) => state.userAccessibility.set_user_accessibility);
  const [xaxisList, setXaxisList] = useState([]);
  const [openEquipmentFilter, setOpenEquipmentFilter] = useState(false);
  const [filterUpdate, setFilterUpdate] = useState(false);
  const [openEnterKpiSlider, setOpenEnterKpiSlider] = useState(false);
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
  let [searchParams] = useSearchParams();
   const plant_code = searchParams.get('plant_code');
  const graphApiCall = () => {
    //Main view
    flexibleEquipmentEnabled?setPayload({
      auth: true,
      url: 'v0/layers/equipments/kpis/graphs/1',
      params: { ...props.idParams, kpi_id: props.kpi_id, kpi_unit_id: props.kpi_unit_id,  plant_code:plant_code,
        layer_id:props?.idParams?.layer_id,
        layer_type:props?.idParams?.layer_type }
    }): setPayload({
      auth: true,
      url: 'v0/equipments/kpis/graphs/1',
      params: { ...props.idParams, kpi_id: props.kpi_id, kpi_unit_id: props.kpi_unit_id }
    });
  };

  useEffect(() => {
    graphApiCall();
  }, [props.idParams, filterUpdate, props.enterKpiUpdate]);

  useEffect(() => {
    if (data && data.data) {
      setChartApiData(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    let tempXaxisList = [];
    if (chartApiData && charRef && charRef.current.chart) {
      charRef.current.chart.series.forEach((eachSeries) => {
        eachSeries.data.forEach(function (p) {
          if (p.y == 0.0) {
            p.update({
              color: 'red',
              className: 'value-present'
            });
            tempXaxisList.push(p.name);
          } else {
            p.update({
              color: null,
              className: 'value-absent'
            });
          }
        });
      });

      setXaxisList(tempXaxisList);
    }
  }, [chartApiData, charRef]);
  const colors= ['#71B2FF', '#A2C3C5', '#C4C4C4', '#9B9B9B','#81a68b']

  const config = {
    ...chartTitleConfig({
      graphTitle: `Energy use (kWh) / ${chartApiData && chartApiData.kpi_name|| ''} (${
        chartApiData && chartApiData.kpi_unit_name||''
      })`,
      userAccessibility: userAccessibility
    }),
    ...chartCommonConfig,
    chart: {
      type: 'column',
      spacingBottom: 10,
      className: 'process-energy-use',
      // scrollablePlotArea: {
      //   minWidth: horScroll,
      // },
      zoomType: 'x',
      style: {
        fontFamily: 'Poppins',
        color: '#4E4E4E'
      }
    },

    exporting: {
      chartOptions: {
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.y:,.2f}'
            }
          }
        }
      }
    },
    // colors: ['#71B2FF', 'orange', 'yellow', '#9B9B9B','red','blue','green'],
    tooltip: {
      useHTML: true,
      valueDecimals: 2, 
      valueSuffix: ` kWh/${chartApiData && chartApiData.kpi_unit_name}`,
      formatter: function () {
        let name = !props.idParams.equipment_id
          ? `<div class="txt-10">${this.point.name}</div>`
          : '';
          let equipmentName = `<span class="txt-10"><span style="color:${this.color}">\u25CF </span>${this.point.equipment_name||''}</span>`
        ;
        let energyUse = `<br><div class="txt-10">Energy use: ${this.point.energy_usage?.toFixed(2)||0} kWh
        </div>`;
        let productionHours = `<br><div class="txt-10">${chartApiData?.kpi_name}: ${this.point.kpi?.toFixed(2)||0} ${chartApiData?.kpi_metric_unit||chartApiData?.kpi_unit_name}
       &nbsp ${this?.point?.input_method==="MANUAL"?"(Manual entry)":(this?.point?.input_method==="AUTO"?"(Automatic entry)":"")} </div>`;
        // Production hours
        return`<div style=" min-width:200px" class="customTooltip">
        ${name}<br>
        <div class=" " >
       ${equipmentName}: <span class="semi-bold">${this.y.toFixed(
          2
        )} kWh/${
          chartApiData && chartApiData.kpi_unit_name
        }</span>
        </div>
        <div>
        ${energyUse} ${productionHours}
        </div>
        </div>`;
        //  return`${name}<br><span class=" txt-10">${equipmentName}: </span><div class="semi-bold">${this.y.toFixed(
        //   2
        // )} kWh/${
        //   chartApiData && chartApiData.kpi_unit_name
        // }</div>${energyUse} ${productionHours}`;
      },
      outside: true,
      positioner: function(labelWidth, labelHeight, point) {
        var x = Math.min(Math.max(point.plotX + this.chart.plotLeft - labelWidth / 2, this.chart.plotLeft), this.chart.plotWidth + this.chart.plotLeft - labelWidth);
        var y = point.plotY + this.chart.plotTop - labelHeight - 5;
        return { x: x, y: y };
      }
    },
    plotOptions: {
      column: {
        cursor: 'pointer',
        //   colorByPoint: true,
        //color: "#71B2FF",
        minPointLength: 5,
        point: {
          events: {
            click: function () {
              // if(data.point.y == 0.0 ){
              //  kpiSharedList = {...kpiSharedList,"openSetKpiMonth":data.point.name,"openKpiScreen":true,"editKpiShow":true}
              // fetchKpiLinks(kpiSharedList)
              // }
            }
          }
        }
      }
    },
    xAxis: {
      type: 'category',
      lineColor: '#cccccc',
      labels: {
        formatter: function () {
          if (xaxisList.includes(this.value)) {
            return `<span class="no-value" style="fill: red;">${this.value}</span>`;
          } else {
            return `<span style="fill:#666;">${this.value}</span>`;
          }
        },

        padding: 10,
        autoRotation: undefined,
        // step:1,
        events: {
          click: function () {
            if (this.value.includes('no-value')) {
              var xaxisLableElement = document.querySelectorAll(
                '.process-energy-use .highcharts-point'
              )[this.pos];
              eventFire(xaxisLableElement, 'click');
            }
          }
        }
      },
      tickLength: 0
    },
    ...chartYaxisConfig({ userAccessibility }),
    series: chartApiData && chartApiData?.equipments?.map((item,index)=>{return{...item,color:colors[index]}})
  };

  return (
    <div>
      {openEnterKpiSlider && (
        <MachineKpiEnterContainer
          viewKpiList={props.viewKpiList}
          idParams={props.idParams}
          openEnterKpiSlider={openEnterKpiSlider}
          setOpenEnterKpiSlider={setOpenEnterKpiSlider}
          setEnterKpiUpdate={props.setEnterKpiUpdate}
        />
      )}
      {openEquipmentFilter && (
        <MachineKpiEquipmentFilterContainer
          idParams={props.idParams}
          graphId={1}
          kpiId={props.kpi_id}
          kpiUnitId={props.kpi_unit_id}
          setOpenEquipmentFilter={setOpenEquipmentFilter}
          setFilterUpdate={setFilterUpdate}
          equipmentsLimit={4}
        />
      )}
      <MachineKpiComparisonGraphComponent
        chartApiData={chartApiData}
        chartData={config}
        charRef={charRef}
        expandWindow={props.expandWindow}
        minimizeWindow={props.minimizeWindow}
        shouldExpand={props.shouldExpand}
        // hasExpand = {props.hasExpand} onExpand = {props.onExpand} onMinimize = {props.onMinimize} downloadChart={downloadChart}
        chartId={props.id}
        hasSettings={!is_operator} //hide settings if its an operator account
        setKpiMachineStatus={props.setKpiMachineStatus}
        setCurrentKpiId={props.setCurrentKpiId}
        kpiId={props.kpi_id}
        kpiUnitId={props.kpi_unit_id}
        isCustomGraph={props.is_custom}
        setCurrentKpiUnitId={props.setCurrentKpiUnitId}
        setOpenEquipmentFilter={setOpenEquipmentFilter}
        setOpenEnterKpiSlider={setOpenEnterKpiSlider}
        idParams={props.idParams}
        setEnterKpiUpdate={props.setEnterKpiUpdate}
        enterKpiUpdate={props.enterKpiUpdate}
        setCustomApiDetails={props.setCustomApiDetails}
        kpiGraphDetails={props.kpiGraphDetails}
      />
    </div>
  );
}

export default MachineKpiComparisonGraphContainer;
