import { useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import EnergyPriceContainer from '../container/energyprice.container';
import EnergyUseContainer from '../container/energyuse.container';

export default function SummarygGraphComponents() {
  const [chartApiData, setChartApiData] = useState(null);
  const [payload, setPayload] = useState(undefined);
  const [energyUseSummaryData, setEnergyUseSummaryData] = useState(undefined);
  //  const [peakLoadSummaryData, setPeakLoadSummaryData] = useState(undefined);
  const [energyPriceSummaryData, setEnergyPriceSummaryData] = useState(null);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false

  useEffect(() => {
    flexibleEquipmentEnabled?
    setPayload({
      auth: true,
      url: 'v0/layers/widget/energy-usage/',
      params:{layer_type:'company'      }
    }):   setPayload({
      auth: true,
      url: 'v1/summary/'
    });
  }, []);
  const [data] = useHttpApiCall(payload);
  useEffect(() => {
    if (data && data.data) {
      setChartApiData(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    if (chartApiData) {
      //var percentageDiff;
      // var last_months = chartApiData.peak_load.last_months;
      // var current_month = chartApiData.peak_load.current_month;
      // if(last_months.length > 0 && current_month.length > 0){
      //     if(last_months[0][1] == 0 && current_month[0][1] == 0){
      //         percentageDiff = Math.abs(0).toFixed(2);
      //     }
      //     else if(last_months[0][1] == 0 && current_month[0][1] != 0){
      //         percentageDiff = false;
      //     }
      //     else{
      //         percentageDiff=Math.abs(( current_month[0][1] - last_months[0][1]  )/last_months[0][1] * 100).toFixed(2)
      //     }
      // }
      // else{
      //     percentageDiff = false;
      // }

      // setPeakLoadSummaryData({
      //     percentageDiff:  percentageDiff,
      //     positiveValue: percentageDiff ? (current_month[0][1] - last_months[0][1]) > 0 ? true : false :false,
      //     data: current_month.length > 0 ? last_months.concat({name:chartApiData.peak_load.current_month[0][0],y:chartApiData.peak_load.current_month[0][1],color: "#71B2FF",}) : last_months
      // })

      // var last_months = chartApiData.energy_usage.last_months;
      // var current_month = chartApiData.energy_usage.current_month;
      // if(last_months.length > 0 && current_month.length > 0){
      //     if(last_months[0][1] == 0 && current_month[0][1] == 0){
      //         percentageDiff = Math.abs(0).toFixed(2);
      //     }
      //     else if(last_months[0][1] == 0 && current_month[0][1] != 0 || last_months[0][1] == null && current_month[0][1]){
      //         percentageDiff = false;
      //     }
      //     else{
      //         percentageDiff=Math.abs(( current_month[0][1] - last_months[0][1]  )/last_months[0][1] * 100).toFixed(2)
      //     }
      // }
      // else{
      //     percentageDiff = false;
      // }
      // setEnergyUseSummaryData({
      //     percentageDiff:  percentageDiff,
      //     positiveValue : percentageDiff ? (current_month[0][1] - last_months[0][1]) > 0 ? true : false :false,
      //     data: current_month.length > 0 ? last_months.concat({name:chartApiData.energy_usage.current_month[0][0],y:chartApiData.energy_usage.current_month[0][1],color: "#71B2FF",}) : last_months
      // })
      getEnergyUseSummaryData(chartApiData.energy_usage);
      if (chartApiData.energy_price) {
        getEnergyPriceSummaryData(chartApiData.energy_price);
      } else {
        setEnergyPriceSummaryData(null);
      }
    }
  }, [chartApiData]);

  const getEnergyPriceSummaryData = (energyPrice) => {
    let today_price = energyPrice.today;
    let tommorrow_price = energyPrice.tomorrow;
    let todayPrice, tommorrowPrice;

    if (today_price.length > 0) {
      todayPrice = today_price[0];
    } else {
      todayPrice = null;
    }

    if (tommorrow_price.length > 0) {
      tommorrowPrice = tommorrow_price[0];
    } else {
      tommorrowPrice = null;
    }

    let summaryPercentage = calculateSummaryPercentage({
      latestValue: tommorrowPrice,
      previousValue: todayPrice
    });
    let configData = getConfigData({
      latestValue: tommorrowPrice,
      latestValueName: 'Tomorrow',
      previousValue: todayPrice,
      previousValueName: 'Now',
      leftEnd: 'previous',
      blueColor: 'previous'
    });

    setEnergyPriceSummaryData({
      ...summaryPercentage,
      data: configData,
      time: energyPrice.time
    });
  };
  const getEnergyUseSummaryData = (energyUse) => {
    let last_months = energyUse.last_months;
    let current_month = energyUse.current_month;
    let lastMonth, currentMonth, lastMonthName, currentMonthName,currentMonthInfo,lastMonthInfo;
    if (last_months.length > 0) {
      lastMonth = last_months[0].y;
      lastMonthName = last_months[0].name;
      lastMonthInfo = last_months[0]
    } else {
      lastMonth = null;
      lastMonthName = null;
      lastMonthInfo = null

    }

    if (current_month.length > 0) {
      currentMonth = current_month[0].y;
      currentMonthName = current_month[0].name;
      currentMonthInfo = current_month[0]
    } else {
      currentMonth = null;
      currentMonthName = null;
      currentMonthInfo = null

    }

    let summaryPercentage = calculateSummaryPercentage({
      latestValue: currentMonth,
      previousValue: lastMonth
    });
    let configData = getConfigData({
      previousInfo: lastMonthInfo,
      latestInfo: currentMonthInfo,
      latestValue: currentMonth,
      latestValueName: currentMonthName,
      previousValue: lastMonth,
      previousValueName: lastMonthName,
      leftEnd: 'previous',
      blueColor: 'latest'
    });
    setEnergyUseSummaryData({
      ...summaryPercentage,
      data: configData
    });
  };

  const getConfigData = ({
    previousInfo,
    latestInfo,
    latestValue,
    latestValueName,
    previousValue,
    previousValueName,
    leftEnd,
    blueColor
  }) => {
    console.log("data",latestInfo)

    let configData = [];
    let dataValues = {
      previous: { name: previousValueName, y: previousValue, label: previousInfo?.label, energy: previousInfo?.energy },
      latest: { y: latestValue, name: latestValueName ,label: latestInfo?.label, energy: latestInfo?.energy }
    };
    dataValues = { ...dataValues, [blueColor]: { ...dataValues[blueColor], color: '#71B2FF' } };
    // console.log('dataValues', dataValues);
    if (dataValues[leftEnd].y) {
      configData.push(dataValues[leftEnd]);
    }

    if (leftEnd == 'previous' && dataValues.latest.y) {
      configData.push(dataValues.latest);
    } else if (leftEnd == 'latest' && dataValues.previous.y) {
      configData.push(dataValues.previous);
    }
    // if(previousValue){
    //     previousData =[[previousValueName,previousValue]];
    // }
    // else{
    //     previousData = [];
    // }

    // if(latestValue){
    //     configData = previousData.concat({name:latestValueName,y:latestValue,color: "#71B2FF",})
    // }
    // else{
    //     configData = previousData
    // }
    return configData;
  };

  const calculateSummaryPercentage = ({ latestValue, previousValue }) => {
    // var last_months = chartApiData.energy_usage.last_months;
    // var current_month = chartApiData.energy_usage.current_month;
    let positiveValue, percentageDiff;
    if (latestValue && previousValue) {
      if (previousValue == 0 && latestValue == 0) {
        percentageDiff = Math.abs(0).toFixed(2);
      } else if (
        (previousValue == 0 && latestValue != 0) ||
        (previousValue == null && latestValue)
      ) {
        percentageDiff = false;
      } else {
        percentageDiff = Math.abs(((latestValue - previousValue) / previousValue) * 100).toFixed(2);
      }
    } else {
      percentageDiff = false;
    }
    positiveValue = percentageDiff ? (latestValue - previousValue > 0 ? true : false) : false;

    return { percentageDiff, positiveValue };
  };

  return (
    <div className="summary-graph">
      <div className="summary-graph__each summary-graph__clickable">
        <EnergyUseContainer summaryData={energyUseSummaryData} />
      </div>
      {/* <div className="summary-graph__each summary-graph__clickable">
                    <PeakLoadContainer SummaryData ={peakLoadSummaryData}/>
                </div> */}
      {energyPriceSummaryData && (
        <div className="summary-graph__each summary-graph__clickable">
          <EnergyPriceContainer summaryData={energyPriceSummaryData} />
        </div>
      )}
      {/* <div className="summary-graph__each">
                    <ProdUnitContainer/>
                </div>
                <div className="summary-graph__each">
                    <WorkHourContainer/>
                </div> */}
    </div>
  );
}
