import { useEffect } from 'react';
import { kpiListSlug } from '../containers/machine.kpi.utilities';
import KpiListComponent from './kpi.list.component';
import KpiUnitsComponent from './kpi.units.component';

export default function SetKpiMachineComponent({
  currentKpiId,
  setKpiMachineStatus,
  setCurrentKpiId,
  currentKpiUnitId,
  setCurrentKpiUnitId,
  kpiList,
  setCustomApiDetails,
  handleSetUnit,
  customApiDetails,
  kpiMachineStatus,
  showKpiBox,
  setKpiIds,
  viewKpiList,
  handleDeleteKpiSet,
  closeKpiSet,
  setCurrentKpiInfo,
  currentKpiInfo
}) {
  useEffect(() => {
    if (currentKpiId) {
      if (currentKpiId == 'custom') {
        setCurrentKpiInfo(null);
      } else {
        if (kpiList.length > 0) {
          let kpiUnitObject = kpiList.find((eachKpi) => eachKpi.id == currentKpiId);
          setCurrentKpiInfo(kpiUnitObject);
          //To preselect if only 1 unit is present
          // if(kpiUnitObject.kpiunits.length == 1){
          //     setCurrentKpiUnitId(kpiUnitObject.kpiunits[0].id)
          // }
        }
      }
    }
  }, [currentKpiId]);

  const customKpiInputHandler = (e) => {
    const { name, value } = e.target;
    setCustomApiDetails((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };

  return (
    <>
      {(!kpiMachineStatus && setKpiIds) || (kpiMachineStatus && viewKpiList.length > 0) ? (
        <div>
          {!kpiMachineStatus && kpiList.length > 0 && (
            <div className="text-center">
              <h6>Select the KPIs you want to track to create the associated graphs.</h6>
              <h6>Entered values are divided by monthly kWh for each equipment.</h6>
            </div>
          )}

          {(showKpiBox && kpiMachineStatus) || !kpiMachineStatus ? (
            <>
              {currentKpiId !== 'custom' && currentKpiInfo && !kpiMachineStatus ? (
                <KpiUnitsComponent
                  setKpiIds={setKpiIds}
                  heading={currentKpiInfo.name}
                  description={kpiListSlug[currentKpiInfo.slug].description}
                  handleSetUnit={handleSetUnit}
                  currentKpiUnitId={currentKpiUnitId}
                  currentKpiInfo={currentKpiInfo}
                  setCurrentKpiUnitId={setCurrentKpiUnitId}
                  closeKpiSet={closeKpiSet}
                  handleDeleteKpiSet={handleDeleteKpiSet}
                  currentKpiId={currentKpiId}
                />
              ) : (
                ''
              )}
              {currentKpiId == 'custom' && !kpiMachineStatus && (
                <KpiUnitsComponent
                  heading={'Custom'}
                  description={kpiListSlug.custom.description}
                  handleSetUnit={handleSetUnit}
                  closeKpiSet={closeKpiSet}
                  handleDeleteKpiSet={handleDeleteKpiSet}
                  currentKpiId={currentKpiId}
                  customApiDetails={customApiDetails}
                  setKpiIds={setKpiIds}
                  customKpiInputHandler={customKpiInputHandler}
                  setCustomApiDetails={setCustomApiDetails}
                />
              )}
              <div className="container px-4 mt-5">
                <div className=" row justify-content-center gx-3">
                  {kpiList &&
                    kpiList.map((eachKpi) => {
                      if (kpiMachineStatus) {
                        //For View KPI show only those KPI's which is not set.
                        if (!setKpiIds.includes(eachKpi.id))
                          return (
                            <KpiListComponent
                              key={`kpilist${eachKpi.id}`}
                              icon={kpiListSlug[eachKpi.slug]?.icon}
                              heading={eachKpi.name}
                              kpiId={ eachKpi.id}
                              // kpiUnitId={eachKpi.kpiunits.id}
                              setCurrentKpiId={setCurrentKpiId}
                              setCurrentKpiUnitId={setCurrentKpiUnitId}
                              setKpiIds={setKpiIds}
                              viewKpiList={viewKpiList}
                              setKpiMachineStatus={setKpiMachineStatus}
                            />
                          );
                      } else {
                        if (eachKpi.id !== currentKpiId)
                          return (
                            <KpiListComponent
                              key={`kpilist${eachKpi.id}`}
                              icon={kpiListSlug[eachKpi.slug]?.icon}
                              heading={eachKpi.name}
                              kpiId={eachKpi.id}

                              // kpiUnitId={eachKpi.kpiunits.id}
                              setCurrentKpiId={setCurrentKpiId}
                              setCurrentKpiUnitId={setCurrentKpiUnitId}
                              setKpiIds={setKpiIds}
                              viewKpiList={viewKpiList}
                              setKpiMachineStatus={setKpiMachineStatus}
                            />
                          );
                      }
                    })}
                  {kpiList.length > 0 ? (
                    (kpiMachineStatus &&
                      !setKpiIds.includes(customApiDetails.custom_kpi_name.id)) ||
                    (!kpiMachineStatus && currentKpiId != 'custom') ? (
                      <KpiListComponent
                        icon={kpiListSlug.custom.icon}
                        heading={'Custom'}
                        kpiId={'custom'}
                        setCurrentKpiId={setCurrentKpiId}
                        setCurrentKpiUnitId={setCurrentKpiUnitId}
                        setKpiIds={setKpiIds}
                        viewKpiList={viewKpiList}
                        setKpiMachineStatus={setKpiMachineStatus}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
}
