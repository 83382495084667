import ExcelDownloadComponent from '../components/exceldownload.component';
import { useEffect, useState } from 'react';
import SelectSourceDownloadContainer from './select.source.download.container';
import SelectSensorClassificationContainer from './select.sensor.classification.container';
import SetDownloadContainer from './set.download.container';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { useSelector } from 'react-redux';
import SetRecurringDownloadContainer from './set.recurring.download.container';
import { useSearchParams } from 'react-router-dom';

export default function ExcelDownloadContainer({ openDownloadSlider, openDownloadScreen }) {
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload); //API for listing all the equipments with id and name
  const [payload2, setPayload2] = useState(undefined);
  const [data2] = useHttpApiCall(payload2); //API can be used to get details for listing the sensor types
  const [apiEquipmentList, setApiEquipmentList] = useState([]);
  const [sensorTypeList, setSensorTypeList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [showToast, setShowToast] = useState(false);
  const is_temp_humidity_classification_enabled = useSelector(
    (state) => state.company.sensor_classification
  );
  const recurring_download = useSelector((state) => state.excelDownload.recurring_download);
  const [equipmentTypeId, setEquipmentTypeId] = useState(null);
  const [selectSensorClassification, setSelectSensorClassification] = useState(false);
  const [sensorUnit, setSensorUnit] = useState(null);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
  let [searchParams] = useSearchParams();
  const process_id = searchParams.get('process_id');
  const plant_id = searchParams.get('plant_id');
  const plant_code = searchParams.get('plant_code');
  const equipment_id = searchParams.get('equipment_id');
  const component_id = searchParams.get('component_id');

  const getLayerId=()=>{
    if(component_id){
      return component_id
    }
    else if(equipment_id){
      return equipment_id
    }
    else if(process_id){
      return process_id
    }
    else if(plant_id){
      return plant_id
    }
    else{
      return null

    }
  }
  const getLayerType=()=>{
    if(component_id){
      return'component'
    }
    else if(equipment_id){
      return 'equipment'
    }
    else if(process_id){
      return 'process'
    }
    else if(plant_id){
      return 'plant'
    }
    else{
      return 'company'

    }
  }
  const DownloadScreenSelect = () => {
    if (openDownloadSlider.select_source) {
      if (is_temp_humidity_classification_enabled && !selectSensorClassification) {
        return (
          <SelectSensorClassificationContainer
            sensorTypeList={sensorTypeList}
            selectEquipmentAPICall={selectEquipmentAPICall}
            setSelectSensorClassification={setSelectSensorClassification}
            setCheckedItems={setCheckedItems}
            setSensorUnit={setSensorUnit}
            setApiEquipmentList={setApiEquipmentList}
          />
        );
      } else {
        return (
          <SelectSourceDownloadContainer
            setCheckedItems={setCheckedItems}
            openDownloadScreen={openDownloadScreen}
            checkedItems={checkedItems}
            apiEquipmentList={apiEquipmentList}
            onSourceNextClick={onSourceNextClick}
            isTempHumidityClassificationEnabled={is_temp_humidity_classification_enabled}
            setSelectSensorClassification={setSelectSensorClassification}
          />
        );
      }
    } else if (openDownloadSlider.set_download) {
      if (recurring_download) {
        return (
          <SetRecurringDownloadContainer
            checkedItems={checkedItems}
            openDownloadScreen={openDownloadScreen}
            setShowToast={setShowToast}
            apiEquipmentList={apiEquipmentList}
            selectSensorClassification={selectSensorClassification}
            sensorUnit={sensorUnit}
            equipmentTypeId={equipmentTypeId}
          />
        );
      } else {
        return (
          <SetDownloadContainer
            checkedItems={checkedItems}
            openDownloadScreen={openDownloadScreen}
            setShowToast={setShowToast}
            apiEquipmentList={apiEquipmentList}
            selectSensorClassification={selectSensorClassification}
            sensorUnit={sensorUnit}
          />
        );
      }
    } else {
      return null;
    }
  };

  const onSourceNextClick = () => {
    openDownloadScreen('set_download');
  };

  const selectEquipmentAPICall = (id) => {
    setEquipmentTypeId(id);
    flexibleEquipmentEnabled? setPayload({
      auth: true,
      url: 'v1/layers/equipments/',
      params: { plant_code: plant_code,layer_type:getLayerType(),layer_id:getLayerId() }
    }):
    setPayload({
      auth: true,
      url: 'v0/equipments/',
      params: { equipment_type: id }
    });
  };
  useEffect(() => {
    if (is_temp_humidity_classification_enabled && openDownloadSlider.select_source) {
      setPayload2({
        auth: true,
        url: 'v0/equipments/types/'
      });
    } else {
      if (apiEquipmentList.length == 0) {
        selectEquipmentAPICall(1);
      }
    }
  }, [openDownloadSlider.select_source]);

  useEffect(() => {
    if (data2 && data2.data) {
      setSensorTypeList(data2.data.data);
    }
  }, [data2]);

  useEffect(() => {
    if (data && data.data) {
      let equipment_list = data.data.data;
      let checked_equipment = {};
      setApiEquipmentList(equipment_list);
      equipment_list.forEach((equipment) => {
        checked_equipment = { ...checked_equipment, [`equipment_${equipment.id}`]: false };
      });
      setCheckedItems(checked_equipment);
    }
  }, [data]);

  useEffect(() => {
    DownloadScreenSelect();
  }, [openDownloadSlider]);

  return (
    <ExcelDownloadComponent
      apiEquipmentList={apiEquipmentList}
      DownloadScreenSelect={DownloadScreenSelect}
      openDownloadScreen={openDownloadScreen}
      showToast={showToast}
      setShowToast={setShowToast}
    />
  );
}
