import { useContext, useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ReactGA from 'react-ga4';
import { context as ProcessContext } from '../../../context/process.context';

export default function KpiMenuComponent({
  openKpiSlider,
  editKpiShow,
  setOpenKpiSlider,
  setEditKpiShow,
  openAlertScreen,
  openDownloadScreen
}) {
  const { trackEvent } = useMatomo();
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
  let parentPlantDetails = useContext(ProcessContext).state.sidebarLinks?.layers
  let [searchParams] = useSearchParams();
  const plant_code = searchParams.get('plant_code');
  useEffect(() => {
    if (openKpiSlider && editKpiShow) {
      trackEvent({ category: 'KPI slider', action: 'KPI settings' });
      ReactGA.event({
        category: 'KPI slider',
        action: 'KPI settings opened'
      });
    } else if (openKpiSlider && !editKpiShow) {
      trackEvent({ category: 'KPI slider', action: 'Enter KPI' });
      ReactGA.event({
        category: 'KPI slider',
        action: 'Enter KPI opened'
      });
    }
  }, [openKpiSlider]);

  return (
    <div>
      <div className="daz-sidebar__hoverinfo shadow">
        <div className="daz-sidebar__dropdown--item">
          <NavLink
            className={({ isActive }) => (isActive ? 'daz-sidebar__hoverinfo--active' : '')}
            to={`${flexibleEquipmentEnabled?`/kpi?plant_code=${plant_code||parentPlantDetails?.[0].plant_code}`:`/kpi`}`}>
            View KPIs
          </NavLink>
        </div>
        <div className="daz-sidebar__dropdown--item">
          <div
            className="daz-sidebar__hoverinfo--click"
            onClick={() => {
              setOpenKpiSlider(true);
              setEditKpiShow(false);
              openAlertScreen('close_slider');
              openDownloadScreen('close_slider');
            }}>
            Enter KPIs
          </div>
        </div>
        <div className="daz-sidebar__dropdown--item">
          <div
            className="daz-sidebar__hoverinfo--click"
            onClick={() => {
              setOpenKpiSlider(true);
              setEditKpiShow(true);
              openAlertScreen('close_slider');
              openDownloadScreen('close_slider');
            }}>
            KPI settings
          </div>
        </div>
      </div>
    </div>
  );
}
