import React, { useRef, useState, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import HomeLayoutComponent from '../../../modules/home/components/homelayout.component';
import useHttpApiCall from '../../../services/useHttpApiCall';
import ProcessLayoutComponent from '../../../modules/processdetail/components/processlayout.component';
import MachineLayoutComponent from '../../../modules/machinedetail/components/machinelayout.component';
import { useLocation } from 'react-router';
import { lineProcessInstantaneousChartFilterButtons } from '../../../config/chartFilterButtons';
import { widgetEndpoints, widgetEndpointsLayers } from '../../../config/widgetEndpoints';
import widgetApiParams from '../../../utilities/widget.api.params';
import processInstantaneousPowerChartConfig from '../../chartdataconfig/mainLayoutConfig/main.process.instantaneous.power.chart.config';
import RefreshMultipleTimeseries from '../../chartautorefresh/refresh.multiple.timeseries';
import DateRangeSet from '../../datetimepicker/daterangeset';
import CompanyLayerLayoutComponent from '../../../modules/companyLayer/components/companyLayerlayout.component';
import EquipmentLayoutComponent from '../../../modules/componentDeail/components/componentlayout.component';
import { useSearchParams } from 'react-router-dom';

export default function MainProcessInstantaneousPowerComponent(props) {
  const INITIALFILTER = '4hr';
  const graphInfo = {
    isSummaryGraph: false
  }
  // const [chartData, setChartData] = useState(undefined);
  const charRef = useRef(undefined);
  const [chartApiData, setChartApiData] = useState([]);
  const [payload, setPayload] = useState(undefined);
  const [selectEquipments, setSelectEquipments] = useState(null);
  const set_equipment_filter = useSelector((state) => state.equipmentFilter.set_equipment_filter);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const onSidbarClick = useSelector((state) => state.sidebarClick.linkClick);
  const [apiParams, setApiParams] = useState(null);
  const [lastTimeStamp, setLastTimeStamp] = useState(null);
  const [totalKwh, setTotalKwh] = useState(null);
  const [minMaxValue, setMinMaxValue] = useState([]); // [min,max]
  const [processedXAxis, setProcessedXAxis] = useState([]); //[processedXAxis,processedYAxis]
  const [equipmentEnergyUsage, setEquipmentEnergyUsage] = useState(null); //[processedXAxis,processedYAxis]
  const location = useLocation();
  const graphTooltip = 'The power consumed at a particular point in time for each equipment.';
  const config = {
    ...processInstantaneousPowerChartConfig(
      chartApiData,
      graphFilter,
      props.graph_name,
      props.userAccessibility,
      graphTooltip,
      setProcessedXAxis,
      setMinMaxValue,
      minMaxValue
    )
  };
  const [payload2, setPayload2] = useState(undefined);
  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [apiDateRange, setApiDateRange] = useState([]);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
  let [searchParams] = useSearchParams();
  // const process_type = searchParams.get('process_type');
  const process_id = searchParams.get('process_id');
  const plant_id = searchParams.get('plant_id');
  const plant_code = searchParams.get('plant_code');
  const equipment_id = searchParams.get('equipment_id');
  const component_id = searchParams.get('component_id');
  const graphApiCall = () => {
    setPayload({
      auth: true,
      url: flexibleEquipmentEnabled?widgetEndpointsLayers?.processInstantaneousPower: widgetEndpoints.processInstantaneousPower,
      params: apiParams
    });
    charRef?.current?.chart?.zoomOut();
    charRef?.current?.chart?.showLoading();
  };

  useEffect(()=>{
  },[props])
  const graphAddPointApiCall = () => {
    // eslint-disable-next-line no-unused-vars
    const { duration, ...modifiedParams } = apiParams;

    setPayload2({
      auth: true,
      url:  flexibleEquipmentEnabled?widgetEndpointsLayers?.processInstantaneousPower:widgetEndpoints.processInstantaneousPower,
      params: { ...modifiedParams, last_reading_time: lastTimeStamp }
    });
  };

  useEffect(() => {
    setGraphFilter((prev) => ({
      ...prev,
      filterMain: INITIALFILTER,
      filterSecondary: graphFilterObject[INITIALFILTER].dropdown[0]
    }));
    charRef?.current?.chart.zoomOut();
  }, [onSidbarClick]);

  useEffect(() => {
    if (apiParams) {
      graphApiCall();
    }
  }, [apiParams]);

  useEffect(() => {
    if (set_equipment_filter.filter_update) {
      graphApiCall();
    }
  }, [set_equipment_filter.filter_update]);

  useEffect(() => {
    setApiParams(widgetApiParams('ProcessInstantaneousPower', props, graphFilter,filterByDateCheck,
    apiDateRange,
    INITIALFILTER));
  }, [graphFilter, location.pathname,apiDateRange]);

  const graphFilterObject = lineProcessInstantaneousChartFilterButtons;
  const [data, loading] = useHttpApiCall(payload);
  const getLayerId=()=>{
    if(component_id){
      return component_id
    }
    else if(equipment_id){
      return equipment_id
    }
    else if(process_id){
      return process_id
    }
    else if(plant_id){
      return plant_id
    }
    else{
      return null

    }
  }
  const getLayerType=()=>{
    if(component_id){
      return'component'
    }
    else if(equipment_id){
      return 'equipment'
    }
    else if(process_id){
      return 'process'
    }
    else if(plant_id){
      return 'plant'
    }
    else{
      return 'company'

    }
  }
  
  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;
      setChartApiData(apiData);
      if (apiData.equipment_energy_usage) {
        // // Extract the keys (timestamps) from the object and convert them to numeric format
        // let timestampsArray = Object.keys(apiData.kwh_data_points).map(function (timestamp) {
        //   return parseInt(timestamp);
        // });

        // // Sort the array of timestamps in ascending order
        // timestampsArray.sort(function (a, b) {
        //   return a - b;
        // });

        setProcessedXAxis(apiData.time_intervals);
        setEquipmentEnergyUsage(apiData.equipment_energy_usage);
      }
      flexibleEquipmentEnabled? setSelectEquipments({
        equipment_length: apiData.equipments_count,
        is_equipment_filter_enabled: apiData.is_equipment_filter_enabled,
        equipmet_list_api_url: 'v1/layers/equipments/',
        drilldown_level: 0,
        equipmet_list_params: { get_equipment_preference: 1 ,get_color_codes:1 ,plant_code:plant_code,layer_type:getLayerType(),layer_id:getLayerId()        }
      }):
      setSelectEquipments({
        equipment_length: apiData.equipments_count,
        is_equipment_filter_enabled: apiData.is_equipment_filter_enabled,
        equipmet_list_api_url: 'v0/equipments/',
        drilldown_level: 0,
        equipmet_list_params: { get_equipment_preference: 1 ,get_color_codes:1 }
      });
      console.log("filter mainnnnnn", graphFilter.filterMain)

      if(filterByDateCheck && (graphFilter.filterMain == null || graphFilter.filterMain == '' )){
        setLastTimeStamp(null);
        console.log("get in")
      }
      else{
        setLastTimeStamp(apiData.last_updated_time);

      }
      charRef?.current?.chart?.hideLoading();
    }
  }, [data]);

  const getClosestTimestampIndex = () => {
    let minValue, minIndex;
    let maxValue, maxIndex;
    // Calculate the closest point interval timestamp
    let minRemainder = minMaxValue[0] % chartApiData.point_interval;
    minValue = minMaxValue[0] + chartApiData.point_interval - minRemainder;

    // Calculate the closest point interval timestamp backwards
    var maxRemainder = minMaxValue[1] % chartApiData.point_interval;
    maxValue = minMaxValue[1] - maxRemainder;

    // In without zoomed conditions highcharts is not providing the closest min and max
    if (minValue < processedXAxis[0]) {
      minValue = processedXAxis[0];
      minIndex = 0;
    } else {
      minIndex = processedXAxis.findIndex((elem) => elem === minValue);
    }

    if (maxValue > processedXAxis[processedXAxis.length - 1]) {
      maxValue = processedXAxis[processedXAxis.length - 1];
      maxIndex = processedXAxis.length - 1;
    } else {
      maxIndex = processedXAxis.findIndex((elem) => elem === maxValue);
    }

    return { minIndex, maxIndex };
  };
 
  useEffect(() => {

    if (minMaxValue.length == 0) {
      setTotalKwh(0);
    } else {
      if (chartApiData && processedXAxis.length > 0) {
        let { minIndex, maxIndex } = getClosestTimestampIndex();

        // Get the index of the minimum and maximum timestamps in the array
        // let minIndex = Math.floor((dataMinMaxValue[0] - processedXAxis[0]) / (2 * 60 * 1000));
        // let maxIndex = Math.floor((dataMinMaxValue[1] - processedXAxis[0]) / (2 * 60 * 1000));
        let minTotalKwh = 0,
          maxTotalKwh = 0;

        // // Find the closest non-null value for the minimum timestamp in forward direction
        // for (let i = minIndex; i < processedXAxis.length && i < maxIndex; i++) {
        //   if (kwhDataPoints[processedXAxis[i]] != null) {
        //     minTotalKwh = kwhDataPoints[processedXAxis[i]];
        //     break;
        //   }
        // }

        // // Find the closest non-null value for the maximum timestamp in backward direction
        // for (let i = maxIndex; i >= 0 && i >= minIndex; i--) {
        //   console.log('closest processed minmax', processedXAxis[i], maxIndex);

        //   if (kwhDataPoints[processedXAxis[i]] != null) {
        //     console.log('inside',processedXAxis[i],kwhDataPoints[processedXAxis[i]], moment(processedXAxis[i]));
        //     maxTotalKwh = kwhDataPoints[processedXAxis[i]];
        //     break;
        //   }
        // }

        for (let i = 0; i < equipmentEnergyUsage.length; i++) {
          let innerArr = equipmentEnergyUsage[i];

          // Find the first non-null value for minIndex by going forward
          let minVal = innerArr[minIndex];
          //console.log('minVal', minVal);
          if (minVal == null) {
            let forwardIndex = minIndex + 1;
            while (forwardIndex < innerArr.length && forwardIndex <= maxIndex) {
              if (innerArr[forwardIndex] != null) {
                minVal = innerArr[forwardIndex];
                break;
              }
              forwardIndex++;
            }
            //console.log('actual minVal', minVal);
          }

          // Find the first non-null value for maxIndex by going backward
          let maxVal = innerArr[maxIndex];
          //console.log('maxVal', maxVal);
          if (maxVal == null) {
            let backwardIndex = maxIndex - 1;
            while (backwardIndex >= 0 && backwardIndex >= minIndex) {
              if (innerArr[backwardIndex] != null) {
                maxVal = innerArr[backwardIndex];
                break;
              }
              backwardIndex--;
            }
            //console.log('actual maxVal', maxVal);
          }

          if (minVal) {
            minTotalKwh += minVal;
          }
          if (maxVal) {
            maxTotalKwh += maxVal;
          }
        }

        //console.log('Min Sum:', minTotalKwh);
        //console.log('Max Sum:', maxTotalKwh);
        let totalKwh = (maxTotalKwh - minTotalKwh).toFixed(2);
        if (totalKwh) {
          //console.log('Total kwh', totalKwh);
          setTotalKwh(totalKwh);
        } else {
          //console.log('0 kwh when min and max is undefined null or NaN');
          setTotalKwh(0);
        }
      } else {
        setTotalKwh(0);
      }
    }
  }, [minMaxValue,processedXAxis]);

  // ****  =====  Note "processedXAxis" removed from above useeffect.in case any issue in total kwh add " [minMaxValue,processedXAxis] =============**"

  return (
    <div className="main-process-intantaneous-power">
      <Suspense fallback={<span>Loadiing</span>}>
        {
        props.drilldownlevel == 'Home' ? (
          <HomeLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            selectEquipments={selectEquipments}
            totalKwh={totalKwh}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}

          />
        ) : props.drilldownlevel == 'Company' ? (
          <CompanyLayerLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            selectEquipments={selectEquipments}
            totalKwh={totalKwh}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}

          />
        ) : props.drilldownlevel == 'Process' ? (
          <ProcessLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            selectEquipments={selectEquipments}
            totalKwh={totalKwh}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}

          />
        ) :
        props.drilldownlevel == 'Plant'|| props.drilldownlevel=='ProcessLayer' ||props.drilldownlevel=='EquipmentLayer'? (
          <ProcessLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            selectEquipments={selectEquipments}
            totalKwh={totalKwh}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}

          />
        ) 
        
        : props.drilldownlevel == 'Machine' ? (
          <MachineLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            hasAlarm={false}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            totalKwh={totalKwh}
            props={props}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}

          />
        ) : props.drilldownlevel == 'ComponentLayer' ? (
          <EquipmentLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            hasAlarm={false}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            totalKwh={totalKwh}
            props={props}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}

          />
        ) : (
          ''
        )}
        {
        
        (filterByDateCheck && (graphFilter.filterMain == null || graphFilter.filterMain == ''))  ? '' : 

        <RefreshMultipleTimeseries
          data={data}
          payload2={payload2}
          charRef={charRef}
          apiParams={apiParams}
          chartApiData={chartApiData}
          setChartApiData={setChartApiData}
          lastTimeStamp={lastTimeStamp}
          setLastTimeStamp={setLastTimeStamp}
          graphApiCall={graphApiCall}
          graphAddPointApiCall={graphAddPointApiCall}
          equipmentEnergyUsage={equipmentEnergyUsage}
          setEquipmentEnergyUsage={setEquipmentEnergyUsage}
          setProcessedXAxis={setProcessedXAxis}
        />
        }
        <DateRangeSet
          graphFilter={graphFilter}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setGraphFilter={setGraphFilter}
          initialFilter={INITIALFILTER}
          setApiDateRange={setApiDateRange}
          graphInfo={graphInfo}
        />
      </Suspense>
    </div>
  );
}
