/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { Suspense, useEffect, useRef, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { machineStatusProductionFilterButtons } from '../../../config/chartFilterButtons';
import { machineStatusEndpoints, machineStatusEndpointsLayers } from '../../../config/widgetEndpoints';
import MachineStatusLayoutComponent from '../components/machine.status.layout.component';
import comparisonConfig from '../../../common/chartdataconfig/machineStatusConfig/comparison.config';
import machineStatusApiParams from '../../../utilities/machine.status.api.params';
import DateRangeSet from '../../../common/datetimepicker/daterangeset';
export default function MachineStatusProductionContainer(props) {
  const INITIALFILTER = '1week';
  const charRef = useRef(undefined);
  const [chartApiData, setChartApiData] = useState([]);
  const [isMachineStatusEmpty, setIsMachineStatusEmpty] = useState(false);
  const [payload, setPayload] = useState(undefined);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const [apiParams, setApiParams] = useState(null);
  const graphFilterObject = machineStatusProductionFilterButtons;
  const [data, loading] = useHttpApiCall(payload);
  const [selectEquipments, setSelectEquipments] = useState(null);
  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [apiDateRange, setApiDateRange] = useState([]);
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
const summeryGraph=true
  const graphInfo = {
    isSummaryGraph: true
  }
  const graphApiCall = () => {
    setPayload({
      auth: true,
      url: flexibleEquipmentEnabled?machineStatusEndpointsLayers.production:machineStatusEndpoints.production,
      params: apiParams
    });
  };
  const config = {
    ...comparisonConfig({
      chartApiData,
      graphFilter,
      graphName: props.graph_name,
      userAccessibility: props.userAccessibility
    })
  };

  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;
      setChartApiData(apiData.machine_status);
      setIsMachineStatusEmpty(apiData.is_machine_status_empty);
      setSelectEquipments({
        equipment_length: apiData.equipment_count,
        is_equipment_filter_enabled: apiData.is_equipment_filter_enabled
      });
    }
  }, [data]);

  useEffect(() => {
    setApiParams(
      machineStatusApiParams({ apiType: 'production', props, graphFilter, INITIALFILTER,apiDateRange ,summeryGraph})
    );
  }, [graphFilter,apiDateRange, location.pathname]);

  // useEffect(() => {
  //   setApiParamsForInstantaneous(widgetApiParams('InstantaneousPower', props, graphFilter, filterByDateCheck,
  //     apiDateRange,
  //     INITIALFILTER));
  // }, [graphFilter, apiDateRange, location.pathname]);

  useEffect(() => {
    if (apiParams) {
      graphApiCall();
    }
  }, [apiParams,props.customizeUpdate.update]);

  const onSetFilterEquipments = () => {
    props.setOpenEquipmentFilterSlider(true);
    props.setFilterGraphId(props.graphId);
  };

  useEffect(() => {
    if(props.filterUpdate.filterGraphId == 1 ){
        graphApiCall()
    }
  }, [props.filterUpdate.update])
  return (
    <div>
      <Suspense fallback={<span>Loading</span>}>
        <MachineStatusLayoutComponent
          chartApiData={chartApiData}
          loading={loading}
          chartData={config}
          charRef={charRef}
          expandWindow={props.expandWindow}
          minimizeWindow={props.minimizeWindow}
          shouldExpand={props.shouldExpand}
          chartId={props.id}
          hasFilter={true}
          graphFilter={graphFilter}
          setGraphFilter={setGraphFilter}
          graphFilterObject={graphFilterObject}
          hasSettings={!is_operator}
          setOpenMachineStatusSlider={props.setOpenMachineStatusSlider}
          isMachineStatusEmpty={isMachineStatusEmpty}
          graph={props.graphNumber}
          onSetFilterEquipments={onSetFilterEquipments}
          setOpenEquipmentFilterSlider={props.setOpenEquipmentFilterSlider}
          selectEquipments={selectEquipments}
          filterByDate={true}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          graphInfo={graphInfo}
        />
           <DateRangeSet
          graphFilter={graphFilter}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setGraphFilter={setGraphFilter}
          initialFilter={INITIALFILTER}
          setApiDateRange={setApiDateRange}
          graphInfo={graphInfo}
        />
      </Suspense>
    </div>
  );
}
