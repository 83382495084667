import moment from 'moment';
import { useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import MachineKpiEnterComponent from '../components/machine.kpi.enter.component';

function MachineKpiEnterContainer({
  idParams,
  openEnterKpiSlider,
  setOpenEnterKpiSlider,
  viewKpiList,
  setEnterKpiUpdate
}) {
  const [payload, setPayload] = useState(null);
  const [payload2, setPayload2] = useState(null);
  const [data] = useHttpApiCall(payload);
  const [data2] = useHttpApiCall(payload2);
  const [kpiApiData, setKpiApiData] = useState(null); //enter kpi get for prepopulating
  const [startDate, setStartDate] = useState(new Date());
  const [kpiMonthShow, setKpiMonthShow] = useState(moment(new Date()).format('MMMM'));
  const [kpiYearShow, setKpiYearShow] = useState(moment(new Date()).format('yyyy'));
  const [kpiMonth, setKpiMonth] = useState(moment(new Date()).format('MM'));
  const [formEdited, setFormEdited] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [updatedEquipments, setUpdatedEquipments] = useState([]);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false

  // Api for getting the value for prepopulating in enter KPI
  useEffect(() => {
    flexibleEquipmentEnabled? setPayload({
      auth: true,
      url: 'v0/layers/equipments/kpis/values/',
      params: { ...idParams, month: kpiMonth, year: kpiYearShow }
    }):
    setPayload({
      auth: true,
      url: 'v0/equipments/kpis/values/',
      params: { ...idParams, month: kpiMonth, year: kpiYearShow }
    });
  }, [kpiYearShow, kpiMonth, data2]);

  useEffect(() => {
    if (data2 && data2.data) {
      setShowToast(true);
      setEnterKpiUpdate((p) => !p);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [data2]);

  useEffect(() => {
    if (data && data.data) {
      setKpiApiData(data.data.data);
    }
  }, [data]);

  const onMonthChange = (date) => {
    setUpdatedEquipments([])
    console.log(date);
    setStartDate(date);
    setKpiMonth(moment(date, 'MMMM yyyy').format('MM'));
    setKpiMonthShow(moment(date, 'MMMM yyyy').format('MMMM'));
    setKpiYearShow(moment(date, 'MMMM yyyy').format('yyyy'));
  };

  const inputHandler = (e,input_method) => {
    let kpi_id = e.target.getAttribute('data-kpi-id');

    let kpi_unit_id = e.target.getAttribute('data-kpi-unit-id');
    let equipment_kpi_id = e.target.id;
    // let kpi_value =e.target.value
    let kpi_value = input_method==='AUTO'?e.target.value.replace('(Automatic entry)',''):e.target.value;
    let tempKpiApiData = [...kpiApiData];
    let currentKpiObject = tempKpiApiData.filter((t) => t.kpi_id == kpi_id);
    console.log('currentKpiObject', currentKpiObject);

    let currentKpiObjectIndex = tempKpiApiData.findIndex(
      (t) => t.kpi_id == kpi_id && t.kpi_unit_id == kpi_unit_id
    );
    let tempKpiApiDataEquipments = [...tempKpiApiData[currentKpiObjectIndex].equipments];
    let currentEquipmentObjectIndex = tempKpiApiDataEquipments.findIndex(
      (t) => t.equipment_kpi_id == equipment_kpi_id
    );
    let updatedEquipmentId=tempKpiApiData[currentKpiObjectIndex].equipments[currentEquipmentObjectIndex].equipment_kpi_id   
    if(updatedEquipments?.length<=0){
      setUpdatedEquipments([...updatedEquipments,updatedEquipmentId])
    }
    else{
     !updatedEquipments?.includes(updatedEquipmentId)&&setUpdatedEquipments([...updatedEquipments,updatedEquipmentId])
    }
    tempKpiApiData[currentKpiObjectIndex].equipments[currentEquipmentObjectIndex].kpi_value =
      kpi_value.replace(/[^\d.]/g, '').trim();
// setApiResponceKpiApiData(data.data.data)

  setKpiApiData(tempKpiApiData);
    setFormEdited(true);
// }

  
  };

  // Enter KPI form submit
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (kpiApiData) {
      const tempKpiApiData = kpiApiData.map(function (kpi) {
        return {
          ...kpi,
          equipments:kpi.equipments.map(eq => ({
            ...eq,
            kpi_value:Number(typeof eq.kpi_value === 'string' ? eq.kpi_value.replace('(Automatic entry)', '').trim() : eq.kpi_value)
          })).filter(function (eq) {
            return eq.kpi_value !== '';
          })
        };
      });
      // Api for creating a editing row
      flexibleEquipmentEnabled? setPayload2({
        auth: true,
        url: `v0/layers/equipments/kpis/values/multi-upsert/`,
        method: 'PUT',
        data: { kpi_date: `${kpiYearShow}-${kpiMonth}-01`, kpi_data: tempKpiApiData },
        params: { ...idParams, month: kpiMonth, year: kpiYearShow }
      }):setPayload2({
        auth: true,
        url: `v0/equipments/kpis/values/multi-upsert/`,
        method: 'PUT',
        data: { kpi_date: `${kpiYearShow}-${kpiMonth}-01`, kpi_data: tempKpiApiData },
        params: { ...idParams, month: kpiMonth, year: kpiYearShow }
      });
    }
  };

  return (
    <MachineKpiEnterComponent
      //  formData = {formData}
      inputHandler={inputHandler}
      onFormSubmit={onFormSubmit}
      startDate={startDate}
      kpiMonthShow={kpiMonthShow}
      kpiYearShow={kpiYearShow}
      onMonthChange={onMonthChange}
      // setOpenKpiSlider = {setOpenKpiSlider}
      formEdited={formEdited}
      setShowToast={setShowToast}
      showToast={showToast}
      openEnterKpiSlider={openEnterKpiSlider}
      setOpenEnterKpiSlider={setOpenEnterKpiSlider}
      viewKpiList={viewKpiList}
      kpiApiData={kpiApiData}
    updatedEquipments={updatedEquipments}
    />
  );
}

export default MachineKpiEnterContainer;
