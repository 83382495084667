import { useState, useContext, useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import EnterkpiContainer from '../../../modules/enterkpi/containers/enterkpi.container';
import KpiMenuComponent from './kpi.menu.component';
import ProcessMenuComponent from './process-menu.component';
import { context as KpiContext } from '../../../context/kpi.context';
import { context as FilterContext } from '../../../context/filter.context';
import AlertsMenuComponent from './alerts.menu.component';
import AlertsComponent from '../../../modules/alerts/components/alerts.component';
import { setAlert } from '../../../redux/alerts/alert.action';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import FilterEquipmentsContainer from '../../../modules/filterequipments/containers/filter.equipments.container';
import { setSidebarClick } from '../../../redux/sidebar/sidebar.action';
import ExcelDownloadContainer from '../../../modules/exceldownload/containers/exceldownload.container';
import ReactGA from 'react-ga4';
import DownloadMenuComponent from './download.menu.component';
import { context as ProcessContext } from '../../../context/process.context';

export default function SidebarComponents() {
  const [openKpiSlider, setOpenKpiSlider] = useState(false);
  const [editKpiShow, setEditKpiShow] = useState(false);
  //const [alertActiveClick, setAlertActiveClick] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  // const [duplicateMode , setDuplicatMode] = useState(false)
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const [openAlertSlider, setOpenAlertSlider] = useState({
    select_source: false,
    active_alerts: false,
    set_alerts: false,
    alerts_screen: false
  });
  const [openFilterSlider, setOpenFilterSlider] = useState(false);
  useSelector((state) => state.equipmentAlert.combination_id);
  var fetchKpiLinks = useContext(KpiContext).fetchKpiLinks;
  let kpiSharedList = useContext(KpiContext).state.kpiLinks;
  var filterSharedList = useContext(FilterContext).state.filterChart;
  var kpiShareStateObject;
  const sidebarClick = useSelector((state) => state.sidebarClick.linkClick);
  const externalAccessEnabled = useSelector((state) => state.company.external_access_enabled);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')
  const company_layer=localStorage.getItem('company_layer')=='true'?true:false
  let [searchParams] = useSearchParams();
  let parentPlantDetails = useContext(ProcessContext).state.sidebarLinks?.layers

  const plant_id = searchParams.get('plant_id');
  const plant_code = searchParams.get('plant_code');
  const plant=searchParams.get('process')
  // const customZoneEnabled =  useSelector(state => state.company.is_zone_enabled)
  //const companyInfo = useSelector((state) => state.companyInfo);
  console.log('externalAccessEnabled', externalAccessEnabled);
  const [openDownloadSlider, setOpenDownloadSlider] = useState({
    select_source: false,
    set_download: false,
    download_screen: false
  });

  const [alertMenuClicked, setAlertMenuClicked] = useState(false);
  const resetStates = () => {
    setCheckedItems({});
    //  setDuplicatMode(false)
    dispatch(setAlert(null));
  };

  const onSidebarClick = () => {
    dispatch(setSidebarClick(!sidebarClick));
  };

  useEffect(() => {
    if (kpiSharedList.openKpiScreen) {
      setOpenKpiSlider(true);
      if (kpiSharedList.editKpiShow) {
        setEditKpiShow(false);
      }
    }
  }, [kpiSharedList.openKpiScreen]);

  useEffect(() => {
    if (!openKpiSlider) {
      kpiShareStateObject = {
        openKpiScreen: false
      };
      fetchKpiLinks(kpiShareStateObject);
    }
  }, [openKpiSlider]);

  const openAlertScreen = (alertScreen) => {
    if (alertScreen == 'set_alerts') {
      setOpenAlertSlider({
        select_source: false,
        active_alerts: false,
        set_alerts: true,
        alerts_screen: true,
      });
    } else if (alertScreen == 'active_alerts') {
      setOpenAlertSlider({
        select_source: false,
        active_alerts: true,
        set_alerts: false,
        alerts_screen: true,
      });
    } else if (alertScreen == 'close_slider') {
      resetStates();
      setOpenAlertSlider({
        select_source: false,
        active_alerts: false,
        set_alerts: false,
        alerts_screen: false,
      });
    } else if (alertScreen == 'select_source') {
      setOpenAlertSlider({
        select_source: true,
        active_alerts: false,
        set_alerts: false,
        alerts_screen: true,
      });
    } 

  };

  const openDownloadScreen = (downloadScreen) => {
    if (downloadScreen == 'set_download') {
      setOpenDownloadSlider({
        select_source: false,
        set_download: true,
        download_screen: true
      });
    } else if (downloadScreen == 'close_slider') {
      setOpenDownloadSlider({
        select_source: false,
        set_download: false,
        download_screen: false
      });
    } else if (downloadScreen == 'select_source') {
      setOpenDownloadSlider({
        select_source: true,
        set_download: false,
        download_screen: true
      });
    }
  };

  const onAlertSidebarClick = (item) => {
    trackEvent({ category: 'Alert', action: 'Sidebar clicked' });
    ReactGA.event({
      category: 'Alert slider',
      action: 'Alert slider opened'
    });
    openAlertScreen(item);
    resetStates();
    setAlertMenuClicked(prev => !prev)
    if (openDownloadSlider.download_screen) {
      openDownloadScreen('close_slider');
    }
  };

  const equipment_combination_id = useSelector((state) => state.equipmentAlert.combination_id);

  useEffect(() => {
    if (equipment_combination_id) {
      openAlertScreen('set_alerts');
    }
  }, [equipment_combination_id]);

  useEffect(() => {
    if (openKpiSlider && (openAlertSlider.alerts_screen || openDownloadSlider.download_screen)) {
      setOpenKpiSlider(false);
    }
  }, [openAlertSlider, openDownloadSlider]);

  //const set_equipment_filter = useSelector((state) => state.equipmentFilter.set_equipment_filter);
  //const filter_button_click = useSelector((state) => state.filterButtonClick.filter_button_click);

  useEffect(() => {
    //console.log('open equipement filter sidebar', set_equipment_filter);
    if (filterSharedList != '') {
      setOpenFilterSlider(true);
      if (openDownloadSlider.download_screen) {
        openDownloadScreen('close_slider');
      }
    } else {
      setOpenFilterSlider(false);
    }
  }, [filterSharedList]);
  const plantLayerLink=`/plant/${plant}?process_type=plant&plant_id=${plant_id}&plant_code=${plant_code}`
  const navHomeLink=flexibleEquipmentEnabled=='true'?company_layer?"/company":plantLayerLink:"/home"

  return (
    <div className="daz-sidebar">
      <div className="daz-sidebar_icons">
        <NavLink
          to={navHomeLink}
          className={({ isActive }) =>
            'daz-sidebar__iconwrap' +
            (isActive && !(openAlertSlider.alerts_screen || openDownloadSlider.download_screen)
              ? ' daz-sidebar__iconwrap--active'
              : '')
          }
          onClick={onSidebarClick}
        >
          <i className="medium material-icons notranslate">home</i>
          <span>Home</span>
        </NavLink>
        <div className="position-relative daz-sidebar__hoverwrp">
          <NavLink
            to="/processes"
            className={({ isActive }) =>
              'daz-sidebar__iconwrap' +
              (isActive && !(openAlertSlider.alerts_screen || openDownloadSlider.download_screen)
                ? ' daz-sidebar__iconwrap--active'
                : '')
            }
          >
            <i className="medium material-icons notranslate">account_tree</i>
            <span>{flexibleEquipmentEnabled=='true'?'Layers':'Processes'}</span>
          </NavLink>
          <ProcessMenuComponent />
        </div>
        <div className="position-relative daz-sidebar__hoverwrp">
          <NavLink
            to={`${flexibleEquipmentEnabled=='true'?`/kpi?plant_code=${plant_code||parentPlantDetails?.[0].plant_code}`:`/kpi`}`}
            // to="/kpi"
            className={({ isActive }) =>
              'daz-sidebar__iconwrap' +
              (isActive && !(openAlertSlider.alerts_screen || openDownloadSlider.download_screen)
                ? ' daz-sidebar__iconwrap--active'
                : '')
            }
          >
            <i className="medium material-icons notranslate">show_chart</i>
            <span>KPI</span>
          </NavLink>
          <KpiMenuComponent
            openKpiSlider={openKpiSlider}
            editKpiShow={editKpiShow}
            setOpenKpiSlider={setOpenKpiSlider}
            setEditKpiShow={setEditKpiShow}
            openAlertScreen={openAlertScreen}
            openDownloadScreen={openDownloadScreen}
          />
        </div>
        <NavLink
          to="/sensor-add-ons"
          className={({ isActive }) =>
            'daz-sidebar__iconwrap' +
            (isActive && !(openAlertSlider.alerts_screen || openDownloadSlider.download_screen)
              ? ' daz-sidebar__iconwrap--active'
              : '')
          }
        >
          <i className="medium material-icons notranslate">equalizer</i>
          <span>
            Sensor <br></br> add-ons
          </span>
        </NavLink>
        <div
          className={
            openAlertSlider.alerts_screen
              ? 'daz-sidebar__iconwrap daz-sidebar__hoverwrp daz-sidebar__iconwrap--active'
              : 'daz-sidebar__iconwrap daz-sidebar__hoverwrp '
          }
        >
          <div onClick={() => onAlertSidebarClick('select_source')}>
            <i className="medium material-icons notranslate" style={{ fontSize: '52px' }}>
              notifications_none
            </i>
            <span>Alerts</span>
          </div>

          <AlertsMenuComponent onAlertSidebarClick={onAlertSidebarClick} />
        </div>
        <div
          className={
            openDownloadSlider.download_screen
              ? 'daz-sidebar__iconwrap daz-sidebar__hoverwrp daz-sidebar__iconwrap--active'
              : 'daz-sidebar__iconwrap daz-sidebar__hoverwrp '
          }>
          <div onClick={() => (externalAccessEnabled ? null : openDownloadScreen('select_source'))}>
            <i className="medium material-icons notranslate">cloud_download</i>
            <span>Download data</span>
          </div>
          {externalAccessEnabled && (
            <DownloadMenuComponent openDownloadScreen={openDownloadScreen} />
          )}
        </div>

        <div className="daz-sidebar__iconwrap daz-sidebar__iconwrap--disabled">
          <i className="medium material-icons notranslate">timeline</i>
          <span>Reduction advice</span>
        </div>
      </div>
      {openKpiSlider ? (
        <EnterkpiContainer
          setOpenKpiSlider={setOpenKpiSlider}
          editKpiShow={editKpiShow}
          setEditKpiShow={setEditKpiShow}
        />
      ) : (
        ''
      )}
      {openAlertSlider.alerts_screen ? (
        <AlertsComponent
          openAlertSlider={openAlertSlider}
          setOpenAlertSlider={setOpenAlertSlider}
          openAlertScreen={openAlertScreen}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          alertMenuClicked = {alertMenuClicked}
        />
      ) : (
        ''
      )}
      {openDownloadSlider.download_screen ? (
        <ExcelDownloadContainer
          openDownloadSlider={openDownloadSlider}
          openDownloadScreen={openDownloadScreen}
        />
      ) : (
        ''
      )}
      {openFilterSlider ? <FilterEquipmentsContainer /> : ''}
    </div>
  );
}
