import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import useHttpApiCall from '../../../services/useHttpApiCall';
import SetKpiMachineComponent from '../components/set.kpi.machine.component';
import { customKpiFormObject } from './machine.kpi.utilities';
import { useSearchParams } from 'react-router-dom';

export default function SetKpiMachineContainer({
  idParams,
  setKpiMachineStatus,
  currentKpiId,
  setCurrentKpiId,
  kpiMachineStatus,
  showKpiBox,
  setKpiIds,
  currentKpiUnitId,
  setCurrentKpiUnitId,
  viewKpiList,
  setUpdateState,
  customApiDetails,
  setCustomApiDetails,
  setKpiList,
  kpiList
}) {
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const [payload2, setPayload2] = useState(null);
  const [data2] = useHttpApiCall(payload2);
  const [payload3, setPayload3] = useState(null);
  const [data3] = useHttpApiCall(payload3);
  const [currentKpiInfo, setCurrentKpiInfo] = useState(null); // Information about that particular KPI including units from API. ie that particular object
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [searchParams] = useSearchParams();

   const plant_code = searchParams.get('plant_code');
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false
  useEffect(() => {
   
  !flexibleEquipmentEnabled&&  setPayload({
      auth: true,
      url: 'v0/kpis/'
    })
  }, []);

  const getAPIParams = () => {
    let apiData;
    if (currentKpiId == 'custom') {
      apiData = {
        custom_kpi_name: customApiDetails.custom_kpi_name.value,
        custom_kpi_unit_name: customApiDetails.custom_kpi_unit.value
      };
    } else {
      apiData = { kpi_id: currentKpiId, kpi_unit_id: currentKpiUnitId };
    }
    return apiData;
  };

  useEffect(() => {
    let apiData = getAPIParams();

    if (confirmEdit) {
      if (currentKpiId == 'custom') {
        if (apiData.custom_kpi_name == '' && apiData.custom_kpi_unit_name == '') {
          setPayload3({
            auth: true,
            url: 'v0/equipments/kpis/multi-delete/',
            method: 'DELETE',
            params: {
              ...idParams,
              kpi_id: customApiDetails.custom_kpi_name.id,
              kpi_unit_id: customApiDetails.custom_kpi_unit.id
            }
          });
        } else {
          flexibleEquipmentEnabled?setPayload2({
            auth: true,
            url: 'v0/layers/equipments/kpis/multi-update/',
            method: 'PATCH',
            params: { ...idParams, kpi_id: customApiDetails.custom_kpi_name.id },
            data: [
              {
                kpi: customApiDetails.custom_kpi_name.id,
                kpi_unit: customApiDetails.custom_kpi_unit.id,
                ...apiData
              }
            ]
          }):setPayload2({
            auth: true,
            url: 'v0/equipments/kpis/multi-update/',
            method: 'PATCH',
            params: { ...idParams, kpi_id: customApiDetails.custom_kpi_name.id },
            data: [
              {
                kpi: customApiDetails.custom_kpi_name.id,
                kpi_unit: customApiDetails.custom_kpi_unit.id,
                ...apiData
              }
            ]
          });
        }
      } else {
        flexibleEquipmentEnabled?  setPayload2({
          auth: true,
          url: 'v0/layers/equipments/kpis/multi-update/',
          method: 'PATCH',
          params: { ...idParams, kpi_id: currentKpiId ,
          },
          data: [
            {
              kpi: currentKpiId,
              kpi_unit: currentKpiUnitId
            }
          ]
        }):
        setPayload2({
          auth: true,
          url: 'v0/equipments/kpis/multi-update/',
          method: 'PATCH',
          params: { ...idParams, kpi_id: currentKpiId },
          data: [
            {
              kpi: currentKpiId,
              kpi_unit: currentKpiUnitId
            }
          ]
        });
      }
    }
  }, [confirmEdit]);

  useEffect(() => {
    if (data && data.data) {
      setKpiList(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    if (data2 && data2.data) {
      setUpdateState((prev) => !prev);
      //  closeKpiSet();
      // setKpiMachineStatus(true)
      setConfirmEdit(false);
    }
  }, [data2]);

  useEffect(() => {
    if (data3 && data3.data) {
      setUpdateState((prev) => !prev);
      closeKpiSet();
      if (customApiDetails.custom_kpi_name.id) {
        setCustomApiDetails(customKpiFormObject);
      }
    }
  }, [data3]);

  const handleDeleteKpiSet = () => {
    let kpiDetail;
    if (currentKpiId == 'custom') {
      kpiDetail = viewKpiList.find((k) => k.kpi.id == customApiDetails.custom_kpi_name.id);
    } else {
      kpiDetail = viewKpiList.find((k) => k.kpi.id == currentKpiId);
    }
    console.log('custom', kpiDetail);
    setPayload3({
      auth: true,
      url: 'v0/equipments/kpis/multi-delete/',
      method: 'DELETE',
      params: { ...idParams, kpi_id: kpiDetail.kpi.id, kpi_unit_id: kpiDetail.kpi_unit.id }
    });
  };

  const handleSetUnit = () => {
    let apiData = getAPIParams();
    if (setKpiIds.includes(currentKpiId)) {
      handleShow();
    } else if (currentKpiId == 'custom' && customApiDetails.custom_kpi_name.id) {
      handleShow();
    } else {
      flexibleEquipmentEnabled? setPayload2({
        auth: true,
        url: 'v0/layers/equipments/kpis/',
        method: 'POST',
        params:{layer_id:idParams?.layer_id,layer_type:idParams?.layer_type,plant_code:plant_code},
        data: { ...idParams, ...apiData }
      }):
      setPayload2({
        auth: true,
        url: 'v0/equipments/kpis/',
        method: 'POST',
        data: { ...idParams, ...apiData }
      });
    }
  };
  const closeKpiSet = (c) => {
    setCurrentKpiId(null);
    currentKpiUnitId && setCurrentKpiUnitId(null);
    setCurrentKpiInfo(null);
    if (c == 'close_button' && setKpiIds && setKpiIds.length > 0) setUpdateState((p) => !p);
  };

  useEffect(() => {
    console.log('currentKpiInfo', currentKpiInfo);
  }, [currentKpiInfo]);

  return (
    <>
      <Modal show={modalShow} onHide={handleClose} centered>
        <div className="modal-wrap">
          <Modal.Header closeButton>
            <h5>Warning!</h5>
          </Modal.Header>
          <Modal.Body>
            Please note that changing a KPI setting also will{' '}
            <strong> remove all previous KPI entries.</strong> <br /> Energy data is still intact
            but you will have to re-enter the KPI values for the previous months.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="btn-grad"
              onClick={() => {
                setConfirmEdit(true);
                handleClose();
              }}>
              Confirm
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      <SetKpiMachineComponent
        setKpiMachineStatus={setKpiMachineStatus}
        currentKpiId={currentKpiId}
        setCurrentKpiId={setCurrentKpiId}
        currentKpiUnitId={currentKpiUnitId}
        setCurrentKpiUnitId={setCurrentKpiUnitId}
        kpiList={kpiList}
        customApiDetails={customApiDetails}
        setCustomApiDetails={setCustomApiDetails}
        handleSetUnit={handleSetUnit}
        kpiMachineStatus={kpiMachineStatus}
        showKpiBox={showKpiBox}
        setKpiIds={setKpiIds}
        viewKpiList={viewKpiList}
        handleDeleteKpiSet={handleDeleteKpiSet}
        closeKpiSet={closeKpiSet}
        currentKpiInfo={currentKpiInfo}
        setCurrentKpiInfo={setCurrentKpiInfo}
      />
    </>
  );
}
